import React, { Component } from "react";
import {
  Header,
  Form,
  Button,
  Divider,
  Accordion,
  Icon
} from "semantic-ui-react";
import Input from "../Shared/Input";
import { Redirect, Link } from "react-router-dom";
import Notification from "../Shared/Notification";
import Name from "./Name";
import Axios from "axios";
import { getToken } from "../lib/csrfToken";
import CustomerPetDetails from "./CustomerPetDetails";
import { toast } from "react-toastify";
import { defaultPet } from "../lib/defaultObjects";
import { checkIfValid } from "../lib/validation";
import Cookies from "universal-cookie/";

class UserSignup extends Component {
  constructor(props) {
    super(props);
    const cookies = new Cookies();
    let webPriceEstimate;
    if ( cookies.get('pet-love-price-estimate') ) {
      webPriceEstimate = JSON.stringify(cookies.get('pet-love-price-estimate'));
    }
    this.state = {
      firstName: "",
      lastName: "",
      Password: "",
      emailAddress: "",
      acceptedPolicies: false,
      homePhone: "",
      workPhone: "",
      alternatePhone: "",
      city: "",
      state: "Texas",
      address: "",
      zipcode: "",
      activeIndex: 0,
      pets: [{ ...defaultPet() }],
      breeds: [],
      animalTypes: [],
      webPriceEstimate: webPriceEstimate 
    };
  }
  componentDidMount() {
    this.getOptions();
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const { activeIndex } = this.state;
    const newIndex = activeIndex === index ? -1 : index;

    this.setState({ activeIndex: newIndex });
  };

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  handleToggle = (e, { name, checked }) => this.setState({ [name]: checked });
  handleSignup = async () => {
    const {
      firstName,
      lastName,
      emailAddress,
      Password,
      acceptedPolicies,
      pets,
      ...rest
    } = this.state;
    if (
      isNotFilled(firstName) ||
      isNotFilled(lastName) ||
      isNotFilled(emailAddress) ||
      isNotFilled(Password)
    ) {
      this.setState({
        notificationMessage:
          "First Name, Last Name, Email, and Password are required."
      });
      return;
    }

    if (
      isNotFilled(rest.address) ||
      isNotFilled(rest.zipcode) ||
      isNotFilled(rest.city) ||
      isNotFilled(rest.state)
    ) {
      this.setState({
        notificationMessage:
          "An Address, City, State, and Zipcode is required for the address."
      });
      return;
    }

    if (isNotFilled(rest.homePhone)) {
      this.setState({
        notificationMessage: "A home phone number is required."
      });
      return;
    }

    if (!checkIfValid("phone", rest.homePhone)) {
      this.setState({
        notificationMessage: "Please enter a valid number for Home Phone"
      });
      return;
    }
    if (
      !isNotFilled(rest.workPhone) &&
      !checkIfValid("phone", rest.workPhone)
    ) {
      this.setState({
        notificationMessage: "Please enter a valid number for Work Phone Number"
      });
      return;
    }
    if (
      !isNotFilled(rest.alternatePhone) &&
      !checkIfValid("phone", rest.alternatePhone)
    ) {
      this.setState({
        notificationMessage:
          "Please enter a valid number for Alternate Phone Number"
      });
      return;
    }

    if (pets && pets.length > 0) {
      for (let pet of pets) {
        if (isNotFilled(pet.petName) || !pet.breedId) {
          this.setState({
            notificationMessage: "A Name and Breed are required for each pet."
          });
          return;
        }
      }
    }

    if (pets && pets.length > 0) {
      for (let pet of pets) {
        if (isNotFilled(pet.petName) || !pet.breedId) {
          this.setState({
            notificationMessage: "A Name and Breed are required for each pet."
          });
          return;
        }
      }
    }

    if (acceptedPolicies !== true) {
      this.setState({
        notificationMessage:
          "Accepting the privacy policy and terms of service are required to create an account."
      });
      return;
    }

    this.setState({ loading: true });
    try {
      let response = await Axios.post(
        "/api/v4/entrance/signup",
        {
          firstName: firstName,
          lastName: lastName,
          Password: Password,
          acceptedPolicies: acceptedPolicies,
          emailAddress: emailAddress,
          pets,
          ...rest
        },
        {
          headers: { "X-CSRF-Token": getToken() }
        }
      );
      if (response.status === 200) {
        this.props.setUser({ ...response.data, isNew: true });
        if (response.data.userId && this.props.history) {
          this.props.history.push(
            "/dashboard/profile/" + response.data.userId,
            { isNewCustomer: true }
          );
        }
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 409) {
        this.setState({
          notificationMessage: "Email already in use by another account."
        });
      } else if (error.response && error.response.status === 400) {
        this.setState({
          notificationMessage: "Password must be a minimum of 10 characters."
        });
      } else {
        this.setState({
          notificationMessage: "Failed to create account."
        });
      }
      this.setState({
        loading: false,
        notificationError: true
      });
    }
  };
  getOptions = async () => {
    try {
      let response = await Axios.get(`/api/v4/entrance/get-sign-up-options`, {
        headers: { "X-CSRF-Token": getToken() }
      });
      if (response.status === 200) {
        this.setState({
          breeds: response.data.breeds,
          animalTypes: response.data.animalTypes
        });
      } else {
        toast.error("Failed to get breeds for selection");
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to save pet.");
    }
  };

  removePet = index => {
    let temp = [...this.state.pets];
    temp.splice(index, 1);
    this.setState({ pets: temp });
  };
  addPet = () => {
    this.setState({
      pets: [...this.state.pets, { ...defaultPet() }]
    });
  };
  onPetChange = (index, newValues) => {
    this.setState(state => {
      let pet = { ...state.pets[index] };
      let tempPets = [...state.pets];
      tempPets.splice(index, 1, { ...pet, [newValues.name]: newValues.value });
      return { pets: tempPets };
    });
  };
  render() {
    let {
      Password,
      emailAddress,
      acceptedPolicies,
      loading,
      homePhone,
      workPhone,
      alternatePhone,
      city,
      state,
      address,
      zipcode,
      activeIndex,
      pets,
      animalTypes,
      breeds,
      ...rest
    } = this.state;
    return (
      <Form size="large">
        {this.state.shouldRedirect && <Redirect to="/dashboard" />}{" "}
        <Header
          as="h2"
          color="purple"
          textAlign="center"
          content="Create New Account"
        />
        <Name
          {...rest}
          required={["firstName", "lastName"]}
          showMiddle={false}
          handleChange={this.handleChange}
        />
        <Input
          value={emailAddress}
          fluid
          required
          isEditing
          validation="email"
          type="email"
          name="emailAddress"
          icon="user"
          iconPosition="left"
          label="Email address"
          onChange={this.handleChange}
        />
        <Input
          fluid
          required
          value={Password}
          isEditing
          name="Password"
          icon="lock"
          iconPosition="left"
          label="Password"
          type="password"
          onChange={this.handleChange}
        />
        <Divider />
        <Header color="purple" content="Information" />
        <Form.Group widths={3}>
          <Input
            label="Home Phone Number"
            value={homePhone}
            required
            onChange={this.handleChange}
            name="homePhone"
            isEditing
            validation="phone"
          />
          <Input
            label="Work Phone Number"
            value={workPhone}
            onChange={this.handleChange}
            name="workPhone"
            validation="phone"
            isEditing
          />
          <Input
            label="Alternate Phone Number"
            value={alternatePhone}
            onChange={this.handleChange}
            name="alternatePhone"
            validation="phone"
            isEditing
          />
        </Form.Group>
        <Divider />
        <Input
          label="Address"
          maxLength={100}
          value={address}
          required
          onChange={this.handleChange}
          name="address"
          isEditing
        />
        <Form.Group widths={2}>
          <Input
            label="City"
            isEditing
            required
            value={city}
            onChange={this.handleChange}
            name="city"
          />
          <Input
            label="State"
            isEditing
            required
            value={state}
            onChange={this.handleChange}
            name="state"
          />
        </Form.Group>
        <Form.Group widths={2}>
          <Input
            label="Zipcode"
            validation="zipcode"
            isEditing
            required
            value={zipcode}
            onChange={this.handleChange}
            name="zipcode"
          />
        </Form.Group>
        <Divider />
        <Header content="Pets" color="purple" />
        <Accordion styled fluid>
          {pets.map((pet, i) => {
            return (
              <div key={i}>
                <Accordion.Title
                  active={activeIndex === i}
                  index={i}
                  onClick={this.handleClick}
                >
                  <Icon name="dropdown" />
                  Pet {i + 1}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === i}>
                  <CustomerPetDetails
                    animalTypes={animalTypes}
                    breeds={breeds}
                    {...pet}
                    handleChange={(values, data) => this.onPetChange(i, data)}
                  />
                  {i > 0 && (
                    <>
                      <Divider hidden />
                      <Button
                        content="Remove Pet"
                        onClick={() => this.removePet(i)}
                      />
                    </>
                  )}
                </Accordion.Content>
              </div>
            );
          })}
        </Accordion>
        <Divider hidden />
        <Button content="Add Pet" color="blue" onClick={this.addPet} />
        <Divider />
        <Form.Checkbox
          label={
            <label>
              I acknowledge that I agree to the{" "}
              <Link target="_blank" to="/termsOfService">
                Terms of Use
              </Link>{" "}
              and have read the{" "}
              <Link target="_blank" to="/privacyPolicy">
                Privacy Policy
              </Link>{" "}
              <span className="required">*</span>
            </label>
          }
          checked={acceptedPolicies}
          onChange={this.handleToggle}
          name="acceptedPolicies"
          id="login-accepted-policies"
        />
        <input type="hidden" name="webPriceEstimate" value={this.state.webPriceEstimate} />
        <Button
          color="purple"
          fluid
          size="large"
          content="Create Account"
          onClick={this.handleSignup}
          loading={loading}
          disabled={loading}
        />
        <Notification
          color={this.state.notificationError ? "blue" : "red"}
          content={this.state.notificationMessage}
        />
      </Form>
    );
  }
}

export default UserSignup;

function isNotFilled(value) {
  return !value || value === "";
}
